import { ICustomerReviews, IJobDetails } from '../../interfaces/shared';
import { AppointmentStatusType } from '../../interfaces/shared-props';
import { IService } from '../../interfaces/zesty';

export { endpoints } from './endpoints';
export { notificationIds } from './notifications';

export const INVOCA_CDN = 'solutions.invocacdn.com/js/invoca-latest.min.js';
export const DEFAULT_MARKET_ID = '7';
export const HVAC_ID = '1012';
export const BASE_URL_PREFIX = '';
export const PRO_APPLY_EMAIL = 'proconnectproleads@frontdoorhome.com';

export const fakeSkuData = {
    status: 'SUCCESS',
    skuPage: {
        paginationInfo: {
            nextPageLink: '',
            prevPageLink: '',
            currentPage: 1,
            totalCount: 0,
            totalPages: 1,
            itemsPerPage: 100,
        },
        skus: [
            {
                skuID: '10003',
                name: 'Appliance Diagnostic (Refunded if repair is completed)',
                skuPrice: 7900,
                shortDescription:
                    "If you don't know exactly what needs to be fixed, our Pro will diagnose the problem for you.  If you choose to perform the repair, the cost of the diagnostic will go towards the cost of the service",
                keywords: [],
                skuCategoryID: '137',
                skuCategoryName: 'ProConnect Call',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342029',
                name: 'Dishwasher Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    "Hire our Pros to repair your broken dishwasher.  Common problems include, dishwasher won't drain, dishwasher won't clean, dishwasher won't start. Brands include Amana, Frigidaire, General Electric (GE), Kenmore, KitchenAid, Maytag, Sears, and Whirlpool.",
                keywords: [],
                skuCategoryID: '360000',
                skuCategoryName: 'Dishwasher Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342030',
                name: 'Refrigerator Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    "Hire our Pros to repair your standard brand refrigerator.  Common problems include, a refrigerator that won't cool, a refrigerator that makes noise, a refrigerator with a bad door seal, a freezer that won't defrost. Brands: Amana, Frigidaire, GE, Kenmore.",
                keywords: [],
                skuCategoryID: '360001',
                skuCategoryName: 'Refrigerator Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342031',
                name: 'Ice Maker Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your standard brand ice maker.  Common problems include, not making ice, frozen ice maker, not making enough ice, ice maker leaking. Brands include Amana, Frigidaire, GE, Kenmore, KitchenAid, Maytag, Sears, and Whirlpool.',
                keywords: [],
                skuCategoryID: '360003',
                skuCategoryName: 'Ice Maker Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342032',
                name: 'Washing Machine Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    "Hire our Pros to repair your standard washing machine maker.  Common problems include, clothes not getting clean, spin cycle not working, washing machine won't start, washing machine smells. Brands include Amana, Frigidaire, GE, Kenmore, Sears.",
                keywords: [],
                skuCategoryID: '360006',
                skuCategoryName: 'Washing Machine Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342033',
                name: 'Dryer Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your standard brand dryer.  Common problems include, clothes not drying, clothes soaking wet, not venting, smelly dryer. Brands include Amana, Frigidaire, General Electric (GE), Kenmore, KitchenAid, Maytag, Sears, and Whirlpool',
                keywords: [],
                skuCategoryID: '360007',
                skuCategoryName: 'Dryer Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342034',
                name: 'Stove or Oven Repair (Standard Brand)',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your standard brand stove, oven, or range.  Common repairs include, oven not getting hot, oven temperature not accurate. Brands include Amana, Frigidaire, General Electric (GE), Kenmore, KitchenAid, Maytag, Sears, and Whirlpool',
                keywords: [],
                skuCategoryID: '360002',
                skuCategoryName: 'Stove & Oven Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342035',
                name: 'Amana Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Amana dishwasher. Common repairs include, dishwasher not draining, dishwasher not cleaning dishes, dishwasher not starting.',
                keywords: [],
                skuCategoryID: '360012',
                skuCategoryName: 'Amana Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342036',
                name: 'Electrolux Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Electrolux dishwasher',
                keywords: [],
                skuCategoryID: '360015',
                skuCategoryName: 'Electrolux Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342037',
                name: 'Frigidaire Dishwasher Repair',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Frigidaire dishwasher',
                keywords: [],
                skuCategoryID: '360017',
                skuCategoryName: 'Frigidaire Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342038',
                name: 'GE Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription: 'Hire our Pros to repair your GE dishwasher',
                keywords: [],
                skuCategoryID: '360018',
                skuCategoryName: 'GE Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342039',
                name: 'Jenn-Air Dishwasher Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Jenn-Air dishwasher',
                keywords: [],
                skuCategoryID: '360019',
                skuCategoryName: 'Jenn-Air Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342040',
                name: 'Kenmore Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Kenmore dishwasher',
                keywords: [],
                skuCategoryID: '360020',
                skuCategoryName: 'Kenmore Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342041',
                name: 'KitchenAid Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your KitchenAid dishwasher',
                keywords: [],
                skuCategoryID: '360021',
                skuCategoryName: 'KitchenAid Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342042',
                name: 'LG Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription: 'Hire our Pros to repair your LG dishwasher',
                keywords: [],
                skuCategoryID: '360022',
                skuCategoryName: 'LG Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342043',
                name: 'Maytag Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Maytag dishwasher',
                keywords: [],
                skuCategoryID: '360010',
                skuCategoryName: 'Maytag Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342044',
                name: 'Thermador Dishwasher Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Thermador dishwasher',
                keywords: [],
                skuCategoryID: '360028',
                skuCategoryName: 'Thermador Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342045',
                name: 'Whirlpool Dishwasher Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Whirlpool dishwasher',
                keywords: [],
                skuCategoryID: '360030',
                skuCategoryName: 'Whirlpool Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342046',
                name: 'Bosch Dishwasher Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Bosch dishwasher',
                keywords: [],
                skuCategoryID: '360013',
                skuCategoryName: 'Bosch Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342047',
                name: 'Dacor Dishwasher Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Dacor dishwasher',
                keywords: [],
                skuCategoryID: '360014',
                skuCategoryName: 'Dacor Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342048',
                name: 'Fisher Paykel Dishwasher Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Fisher Paykel dishwasher',
                keywords: [],
                skuCategoryID: '360016',
                skuCategoryName: 'Fisher Paykel Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342049',
                name: 'Miele Dishwasher Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Miele dishwasher',
                keywords: [],
                skuCategoryID: '360024',
                skuCategoryName: 'Miele Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342050',
                name: 'Samsung Dishwasher Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Samsung dishwasher',
                keywords: [],
                skuCategoryID: '360025',
                skuCategoryName: 'Samsung Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342051',
                name: 'Sub-Zero Dishwasher Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Sub-Zero dishwasher',
                keywords: [],
                skuCategoryID: '360026',
                skuCategoryName: 'Sub-Zero Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342052',
                name: 'Viking Dishwasher Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Viking dishwasher',
                keywords: [],
                skuCategoryID: '360029',
                skuCategoryName: 'Viking Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342054',
                name: 'Add-On: Built-In or Stackable Appliance Repair ',
                skuPrice: 8600,
                shortDescription:
                    'Add on labor for our Pros to repair a built-in or stackable appliance',
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342055',
                name: 'Add-On: Dishwasher Motor Repair or Replacement ',
                skuPrice: 7400,
                shortDescription:
                    'Add-on labor for dishwasher motor repairs or replacements',
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342056',
                name: 'Add-On: Refrigerator Compressor, Condenser, or Evaporator Repair',
                skuPrice: 14900,
                shortDescription:
                    "Add on labor for our Pros to repair or replace your referigerator's sealed system including compressor, condensor, and evaporator",
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342058',
                name: 'Add-On: Refrigerator or Freezer Clean Out or Defrosting',
                skuPrice: 7400,
                shortDescription:
                    'Add-on labor to clean out or defrost refrigerator or freezer before repair',
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342059',
                name: 'Add-On: Washing Machine or Dryer Drum Repair or Replacement ',
                skuPrice: 9900,
                shortDescription:
                    'Add on labor for our Pros to repair or replace your washing machine or dryer drum ',
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342060',
                name: 'Add-On: Water or Gas Supply Lines Disconnection ',
                skuPrice: 7400,
                shortDescription:
                    'Add on labor for when our Pros need to disconnect the water or gas supply line to repair an appliance',
                keywords: [],
                skuCategoryID: '360031',
                skuCategoryName: 'Appliance Repair Labor Add-Ons',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342061',
                name: 'Dishwasher Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your premium brand dishwasher.  Common repairs include, fixing dishwasher drain issues, repairing a dishwasher that is not cleaning, repairing a dishwasher that is not starting. Brands: Bosch, Jenn-Air, LG, Samsung, Thermador.',
                keywords: [],
                skuCategoryID: '360000',
                skuCategoryName: 'Dishwasher Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342062',
                name: 'Refrigerator Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your premium brand refrigerator.  Common problems include, a refrigerator that will not cool, a refrigerator that makes noise, a refrigerator with a bad door seal, a freezer that will not defrost. Brands: Bosch, GE, Jenn-Air, LG.',
                keywords: [],
                skuCategoryID: '360001',
                skuCategoryName: 'Refrigerator Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342063',
                name: 'Ice Maker Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your premium brand ice maker.  Common problems include, ice maker does not make ice, ice maker is frozen, ice maker is not making enough ice, ice maker is leaking. Brands include Bosch, GE Monogram,  Jenn-Air, LG, and Samsung',
                keywords: [],
                skuCategoryID: '360003',
                skuCategoryName: 'Ice Maker Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342064',
                name: 'Washing Machine Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    "Hire our Pros to repair your premium brand washing machine.  Common problems, washing machine won't clean clothes, washing machine spin cycle is broken, washing machine will not start, washing machine shakes. Brands include Bosch, Jenn-Air, Samsung.",
                keywords: [],
                skuCategoryID: '360006',
                skuCategoryName: 'Washing Machine Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342065',
                name: 'Dryer Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    "Hire our Pros to repair your premium brand dryer.  Common problems include, clothes won't dry, clothes are still wet, dryer is not venting. Brands include Bosch, GE Monogram,  Jenn-Air, LG, Miele, and Samsung",
                keywords: [],
                skuCategoryID: '360007',
                skuCategoryName: 'Dryer Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342066',
                name: 'Stove or Oven Repair (Premium Brand)',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your premium brand stove, oven or range.  Common problems include, oven not working, oven not getting hot, oven light not working. Brands include Bosch, GE Monogram,  Jenn-Air, LG, Samsung, and Thermador',
                keywords: [],
                skuCategoryID: '360002',
                skuCategoryName: 'Stove & Oven Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342067',
                name: 'Amana Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Amana refrigerator. Common problems include, refrigerator not getting cold, freezer not defrosting, refrigerator door seal worn out, broken refrigerator light.',
                keywords: [],
                skuCategoryID: '360012',
                skuCategoryName: 'Amana Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342068',
                name: 'Electrolux Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Electrolux refrigerator',
                keywords: [],
                skuCategoryID: '360015',
                skuCategoryName: 'Electrolux Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342069',
                name: 'Frigidaire Refrigerator Repair',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Frigidaire refrigerator',
                keywords: [],
                skuCategoryID: '360017',
                skuCategoryName: 'Frigidaire Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342070',
                name: 'GE Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your GE refrigerator',
                keywords: [],
                skuCategoryID: '360018',
                skuCategoryName: 'GE Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342071',
                name: 'Jenn-Air Refrigerator Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Jenn-Air refrigerator',
                keywords: [],
                skuCategoryID: '360019',
                skuCategoryName: 'Jenn-Air Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342072',
                name: 'Kenmore Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Kenmore refrigerator',
                keywords: [],
                skuCategoryID: '360020',
                skuCategoryName: 'Kenmore Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342073',
                name: 'KitchenAid Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your KitchenAid refrigerator',
                keywords: [],
                skuCategoryID: '360021',
                skuCategoryName: 'KitchenAid Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342074',
                name: 'LG Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your LG refrigerator',
                keywords: [],
                skuCategoryID: '360022',
                skuCategoryName: 'LG Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342075',
                name: 'Maytag Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Maytag refrigerator',
                keywords: [],
                skuCategoryID: '360010',
                skuCategoryName: 'Maytag Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342076',
                name: 'Thermador Refrigerator Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Thermador refrigerator',
                keywords: [],
                skuCategoryID: '360028',
                skuCategoryName: 'Thermador Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342077',
                name: 'Whirlpool Refrigerator Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Whirlpool refrigerator',
                keywords: [],
                skuCategoryID: '360030',
                skuCategoryName: 'Whirlpool Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342078',
                name: 'Bosch Refrigerator Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Bosch refrigerator',
                keywords: [],
                skuCategoryID: '360013',
                skuCategoryName: 'Bosch Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342079',
                name: 'Dacor Refrigerator Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Dacor refrigerator',
                keywords: [],
                skuCategoryID: '360014',
                skuCategoryName: 'Dacor Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342080',
                name: 'Fisher Paykel Refrigerator Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Fisher Paykel refrigerator',
                keywords: [],
                skuCategoryID: '360016',
                skuCategoryName: 'Fisher Paykel Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342081',
                name: 'Miele Refrigerator Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Miele refrigerator',
                keywords: [],
                skuCategoryID: '360024',
                skuCategoryName: 'Miele Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342082',
                name: 'Samsung Refrigerator Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Samsung refrigerator',
                keywords: [],
                skuCategoryID: '360025',
                skuCategoryName: 'Samsung Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342083',
                name: 'Sub-Zero Refrigerator Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Sub-Zero refrigerator',
                keywords: [],
                skuCategoryID: '360026',
                skuCategoryName: 'Sub-Zero Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342084',
                name: 'Viking Refrigerator Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Viking refrigerator',
                keywords: [],
                skuCategoryID: '360029',
                skuCategoryName: 'Viking Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342086',
                name: 'Amana Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Amana ice maker. Common problems include, ice maker not making ice, ice maker frozen, ice maker leaking, ice maker not making enough ice.',
                keywords: [],
                skuCategoryID: '360012',
                skuCategoryName: 'Amana Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342087',
                name: 'Electrolux Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Electrolux ice maker',
                keywords: [],
                skuCategoryID: '360015',
                skuCategoryName: 'Electrolux Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342088',
                name: 'Frigidaire Ice Maker Repair',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Frigidaire ice maker',
                keywords: [],
                skuCategoryID: '360017',
                skuCategoryName: 'Frigidaire Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342089',
                name: 'GE Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription: 'Hire our Pros to repair your GE ice maker',
                keywords: [],
                skuCategoryID: '360018',
                skuCategoryName: 'GE Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342090',
                name: 'Jenn-Air Ice Maker Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Jenn-Air ice maker',
                keywords: [],
                skuCategoryID: '360019',
                skuCategoryName: 'Jenn-Air Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342091',
                name: 'Kenmore Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Kenmore ice maker',
                keywords: [],
                skuCategoryID: '360020',
                skuCategoryName: 'Kenmore Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342092',
                name: 'KitchenAid Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your KitchenAid ice maker',
                keywords: [],
                skuCategoryID: '360021',
                skuCategoryName: 'KitchenAid Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342093',
                name: 'LG Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription: 'Hire our Pros to repair your LG ice maker',
                keywords: [],
                skuCategoryID: '360022',
                skuCategoryName: 'LG Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342094',
                name: 'Maytag Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Maytag ice maker',
                keywords: [],
                skuCategoryID: '360010',
                skuCategoryName: 'Maytag Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342095',
                name: 'Thermador Ice Maker Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Thermador ice maker',
                keywords: [],
                skuCategoryID: '360028',
                skuCategoryName: 'Thermador Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342096',
                name: 'Whirlpool Ice Maker Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Whirlpool ice maker',
                keywords: [],
                skuCategoryID: '360030',
                skuCategoryName: 'Whirlpool Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342097',
                name: 'Bosch Ice Maker Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Bosch ice maker',
                keywords: [],
                skuCategoryID: '360013',
                skuCategoryName: 'Bosch Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342098',
                name: 'Dacor Ice Maker Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Dacor ice maker',
                keywords: [],
                skuCategoryID: '360014',
                skuCategoryName: 'Dacor Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342099',
                name: 'Fisher Paykel Ice Maker Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Fisher Paykel ice maker',
                keywords: [],
                skuCategoryID: '360016',
                skuCategoryName: 'Fisher Paykel Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342100',
                name: 'Miele Ice Maker Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Miele ice maker',
                keywords: [],
                skuCategoryID: '360024',
                skuCategoryName: 'Miele Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342101',
                name: 'Samsung Ice Maker Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Samsung ice maker',
                keywords: [],
                skuCategoryID: '360025',
                skuCategoryName: 'Samsung Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342102',
                name: 'Sub-Zero Ice Maker Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Sub-Zero ice maker',
                keywords: [],
                skuCategoryID: '360026',
                skuCategoryName: 'Sub-Zero Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342103',
                name: 'Viking Ice Maker Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Viking ice maker',
                keywords: [],
                skuCategoryID: '360029',
                skuCategoryName: 'Viking Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342105',
                name: 'Amana Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Amana stove, oven or range. Common problems include, oven not getting hot, oven temperature not accurate, oven light broken, oven gas smell.',
                keywords: [],
                skuCategoryID: '360012',
                skuCategoryName: 'Amana Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342106',
                name: 'Electrolux Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Electrolux stove, oven or range',
                keywords: [],
                skuCategoryID: '360015',
                skuCategoryName: 'Electrolux Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342107',
                name: 'Frigidaire Stove, Oven or Range Repair',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Frigidaire stove, oven or range',
                keywords: [],
                skuCategoryID: '360017',
                skuCategoryName: 'Frigidaire Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342108',
                name: 'GE Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your GE stove, oven or range',
                keywords: [],
                skuCategoryID: '360018',
                skuCategoryName: 'GE Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342109',
                name: 'Jenn-Air Stove, Oven or Range Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Jenn-Air stove, oven or range',
                keywords: [],
                skuCategoryID: '360019',
                skuCategoryName: 'Jenn-Air Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342110',
                name: 'Kenmore Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Kenmore stove, oven or range',
                keywords: [],
                skuCategoryID: '360020',
                skuCategoryName: 'Kenmore Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342111',
                name: 'KitchenAid Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your KitchenAid stove, oven or range',
                keywords: [],
                skuCategoryID: '360021',
                skuCategoryName: 'KitchenAid Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342112',
                name: 'LG Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your LG stove, oven or range',
                keywords: [],
                skuCategoryID: '360022',
                skuCategoryName: 'LG Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342113',
                name: 'Maytag Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Maytag stove, oven or range',
                keywords: [],
                skuCategoryID: '360010',
                skuCategoryName: 'Maytag Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342114',
                name: 'Thermador Stove, Oven or Range Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Thermador stove, oven or range',
                keywords: [],
                skuCategoryID: '360028',
                skuCategoryName: 'Thermador Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342115',
                name: 'Whirlpool Stove, Oven or Range Repair ',
                skuPrice: 17400,
                shortDescription:
                    'Hire our Pros to repair your Whirlpool stove, oven or range',
                keywords: [],
                skuCategoryID: '360030',
                skuCategoryName: 'Whirlpool Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342116',
                name: 'Bosch Stove, Oven or Range Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Bosch stove, oven or range',
                keywords: [],
                skuCategoryID: '360013',
                skuCategoryName: 'Bosch Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342117',
                name: 'Dacor Stove, Oven or Range Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Dacor stove, oven or range',
                keywords: [],
                skuCategoryID: '360014',
                skuCategoryName: 'Dacor Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342118',
                name: 'Fisher Paykel Stove, Oven or Range Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Fisher Paykel stove, oven or range',
                keywords: [],
                skuCategoryID: '360016',
                skuCategoryName: 'Fisher Paykel Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342119',
                name: 'Miele Stove, Oven or Range Repair ',
                skuPrice: 36900,
                shortDescription:
                    'Hire our Pros to repair your Miele stove, oven or range',
                keywords: [],
                skuCategoryID: '360024',
                skuCategoryName: 'Miele Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
            {
                skuID: '342120',
                name: 'Samsung Stove, Oven or Range Repair ',
                skuPrice: 22400,
                shortDescription:
                    'Hire our Pros to repair your Samsung stove, oven or range',
                keywords: [],
                skuCategoryID: '360025',
                skuCategoryName: 'Samsung Repair',
                serviceTypeID: '1013',
                serviceTypeName: 'Appliance Repair',
            },
        ],
    },
};

export const REVIEWS_VALUES: ICustomerReviews = {
    '1013': {
        // APL
        averageRating: '4.6',
        totalReviews: '78560',
    },
    '1004': {
        // CAR
        averageRating: '4.8',
        totalReviews: '31010',
    },
    '1001': {
        // PLB
        averageRating: '4.7',
        totalReviews: '10742',
    },
    '1064': {
        // ELE
        averageRating: '4.7',
        totalReviews: '7637',
    },
    '1012': {
        // HVAC
        averageRating: '4.6',
        totalReviews: '2305',
    },
    '1008': {
        // Handyman
        averageRating: '4.7',
        totalReviews: '23381',
    },
    '1023': {
        // Dryer Vent
        averageRating: '4.8',
        totalReviews: '3574',
    },
    '1061': {
        // Drain
        averageRating: '4.7',
        totalReviews: '3439',
    },
    '1010': {
        // Window
        averageRating: '4.8',
        totalReviews: '1181',
    },
    '1007': {
        // Gutter
        averageRating: '4.8',
        totalReviews: '512',
    },
    '1003': {
        // ELE
        averageRating: '4.7',
        totalReviews: '7637',
    },
};

export const FALLBACK_FEE_CONTENT = {
    label: `diagnostic fee`,
    content: `The diagnostic fee will be collected by your pro and covers the cost of the Pro’s initial visit and assessment of the issue. If your repair is completed, the diagnostic fee will be waivered or credited back on your invoice.`,
};
export const FALLBACK_HIW_CONTENT = {
    step_1_title: 'Pick A Service',
    step_1_description:
        'Find your service, check out the up-front prices, and highly reviewed Independent Service Pros.',
    step_2_title: 'Book Online',
    step_2_description:
        'Simply tell us what the problem is and when and where a Pro should show up.',
    step_3_title: 'Pay After Work is Done',
    step_3_description:
        'We connect you to a Pro who will fix your problem - you pay only after the work is done.',
};

export const EMAIL_PATTERN = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/g;
export const PHONE_PATTERN = /^\d{10,11}$/g;
export const ZIPCODE_PATTERN = /^[0-9]{5}$/;
export const JOB_DETAILS_PLACEHOLDER: IJobDetails = {
    '1013': 'Please tell us what type of appliance you have, the brand, and a short description of the problem.',
    '1004': 'Tell us more about your job. How many rooms do you need cleaned? Do you have stairs, area rugs, or furniture that you want cleaned? What else should we know?',
};

export const FALLBACK_JOB_DETAILS_PLACEHOLDER =
    'Please provide a short description.';

export const SLOT_TYPES = [
    'Slot type not set',
    'Morning',
    'Afternoon',
    'Evening',
    'Anytime',
];
export const SKU_CONSTANTS = {
    residentialRepairID: '6a242ba1-a127-4190-ae21-0266effec5b2',
    userRole: 'customer',
    applianceSubCatID: '137',
};

export const LOCAL_STORAGE_KEYS = {
    hashLinks: 'hlinks',
    legacyUserId: 'uid',
    appointmentId: 'appt-id',
    appointmentHash: 'appt-hsh',
};

export const SESSION_STORAGE_KEYS = {
    bookComplete: 'bookComplete',
    rescheduleComplete: 'rescheduleComplete',
    pageLandingInfo: 'pageLandingInfo',
    homeBookingParams: 'homeBookParams',
};

export const HERO_BACKGROUND_SRC =
    'https://cdn.frontdoorhome.com/ahs/proconnect/prod/static/ahs/all_market_proconnect_home_page_hero.png';

export const SERVICE_BACKGROUNDS: Record<string, string> = {
    '1004': 'https://cdn.optimizely.com/img/17026851333/a85c014a622a4325b141ba117ebb65d7.png',
    '1013': 'https://cdn.optimizely.com/img/17026851333/ca533215dffe43259e1ef09a536517eb.jpg',
    '1001': 'https://cdn.optimizely.com/img/17026851333/264ea6f69b6441c4b44843579f7d7f5b.png',
    '1003': 'https://cdn.optimizely.com/img/17026851333/fe3fc1386bb948b19ab9bb33b823af72.png',
    '1010': 'https://cdn.optimizely.com/img/17026851333/74d7178d713646f7b641d35eb6a687ec.png',
    '1011': 'https://cdn.optimizely.com/img/17026851333/178bb5a70e7f4b09a545532161398f29.png',
    '1007': 'https://cdn.optimizely.com/img/17026851333/7ffe4671f5be4443b2bfe6e7ebaa9c8e.png',
    '1023': 'https://cdn.optimizely.com/img/17026851333/11b839ed5dac446383102ec77ed28b30.png',
};

export const BRAND_LOGOS = [
    'logo1',
    'logo2',
    'logo3',
    'logo4',
    'logo5',
    'logo6',
    'logo7',
];

export const GC_TERMS_ID = 'vczrr12f-2333-4d5e-96c4-iu978f2e9876';
export const NON_GC_TERMS_ID = 'trq3412h-29t3-4d5e-75e4-9b978f2e9811';
export const GC_TERMS_ID_DEV = 'aeac5dab-8324-4050-82d9-495f9f5af501';
export const NON_GC_TERMS_ID_DEV = '7fc10847-8739-4c89-ac57-8da8b612b70d';

export const TERMS_ID_NAME_MAP: Record<string, string> = {
    [GC_TERMS_ID]: 'gc',
    [NON_GC_TERMS_ID]: 'non-gc',
    [GC_TERMS_ID_DEV]: 'gc',
    [NON_GC_TERMS_ID_DEV]: 'non-gc',
};

export const SERVICE_TYPE_IDS = {
    carpetCleaning: '1004',
    dryerVent: '1023',
};

export const ORGANIC_SIDS = {
    referral: '236',
    search: '235',
    sem: '201',
};

export const APPT_CANCEL_OTHER_REASON_ID = '67';

export const STATUS_TYPES = {
    pending: '1',
    active: '2',
    authorized: '5',
    declined: '6',
    paid: '7',
    partial: '8',
    canceled: '9',
    completed: '11',
    enRoute: '13',
    onSite: '14',
    rescheduled: '15',
};

export const APPOINTMENT_STATUS_TYPES: Record<string, AppointmentStatusType> = {
    pastDue: 'Past Due',
    closed: 'Closed',
    onTheJob: 'On The Job',
    enroute: 'En Route',
    booked: 'Booked',
    complete: 'Complete',
    estimated: 'Estimated',
    canceled: 'Canceled',
    open: 'Open',
    unknown: 'Unknown',
};

export const ATTRIBUTE_KEYS = {
    invoicePastDue: '1146',
};

export const BOOKING_COMPLETE_PATH = '/booking-complete';
export const DAYS_INDICES = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
};

export const EASTERN_TZ_STATE_CODES = [
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'IN',
    'KY',
    'MA',
    'MD',
    'ME',
    'MI',
    'NC',
    'NH',
    'NJ',
    'NY',
    'OH',
    'PA',
    'RI',
    'SC',
    'TN',
    'VA',
    'VT',
    'WV',
];
