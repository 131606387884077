import { IService } from '../interfaces/zesty';
import {
    createContext,
    MutableRefObject,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    IGeolocationData,
    IMarket as IMarketFull,
    IMarketServices,
    IServiceType,
} from '../interfaces/shared';

export type IMarket = Omit<IMarketFull, 'uuid'>;
export type VariationName = 'JDC';

interface IPagesContext {
    readonly services: Array<IService>;
    readonly setServices: (services: Array<IService>) => void;
    readonly marketsServices: Array<IMarketServices>;
    readonly userMarket: IMarket;
    readonly setUserMarket: (market: IMarket) => void;
    readonly setMarketsServices: (
        marketsServices: Array<IMarketServices>,
    ) => void;
    readonly availableServices: Array<IServiceType>;
    variations: Partial<Record<VariationName, boolean>>;
    setVariation: (name: string) => void;
    isAddVariationReady?: boolean;
    setIsAddVariationReady: (isAddVariationReady?: boolean) => void;
}

const pagesInit: IPagesContext = {
    marketsServices: [],
    userMarket: {} as IMarket,
    setMarketsServices: () => undefined,
    setUserMarket: () => undefined,
    services: [],
    setServices: () => undefined,
    availableServices: [],
    variations: {},
    setVariation: () => null,
    isAddVariationReady: false,
    setIsAddVariationReady: () => null,
};

export const PagesContext = createContext<IPagesContext>(pagesInit);

function PagesProvider({ children }: { children: JSX.Element }) {
    const [userMarket, setUserMarket] = useState(pagesInit.userMarket);
    const [marketsServices, setMarketsServices] = useState<
        Array<IMarketServices>
    >([]);
    const [services, setServices] = useState<Array<IService>>([]);
    const [isAddVariationReady, setIsAddVariationReady] = useState<
        boolean | undefined
    >(false);
    // Optimizely Variations
    const [variations, setVariations] = useState<
        Partial<Record<VariationName, boolean>>
    >({});

    const availableServices = useMemo(() => {
        const found = marketsServices.find(
            (el) => el.marketId === userMarket.id,
        );
        if (!found) {
            return [];
        }

        return found.services;
    }, [marketsServices, userMarket.id]);

    const setVariation = (name: string) => {
        if (name === '') {
            return;
        }

        setVariations((prev) => ({
            ...prev,
            [name]: true,
        }));
    };

    const _setIsAddVariationReady = (isAddVariationReady?: boolean) => {
        if (isAddVariationReady === undefined) {
            return;
        }

        setIsAddVariationReady(isAddVariationReady);
    };

    return (
        <PagesContext.Provider
            value={{
                marketsServices,
                userMarket,
                setUserMarket,
                setMarketsServices,
                services,
                setServices,
                availableServices,
                variations,
                setVariation,
                isAddVariationReady,
                setIsAddVariationReady: _setIsAddVariationReady,
            }}
        >
            {children}
        </PagesContext.Provider>
    );
}

export { PagesProvider };
