import getConfig from 'next/config';

const {
    publicRuntimeConfig: {
        API_BASE_URL,
        API_BASE_URL_INTRA_MESH,
        ZESTY_BASE_URL,
        SERVIZ_API_BASE_URL,
    },
} = getConfig();

const MARKET_SERVICES_URL = 'od-market-services/v1';
const VENDOR_PRO_MIDDLEWARE = 'vendor-pro-middleware';
const SERVIZ_API_PREFIX_V2 = '/api/v2.0/json';
const SERVIZ_API_PREFIX_V3 = '/api/v3.0/json';

export const endpoints = {
    zesty: {
        guarantees: getZestyUrl('/frontdoorpro/guarantees'),
        pricing: getZestyUrl('/proconnect/pricing'),
        semHeader: getZestyUrl('/proconnect/sem-header'),
        specials: getZestyUrl('/proconnect/specials'),
        howItWorks: getZestyUrl('/frontdoorpro/how-it-works'),
        hiwPageContent: getZestyUrl('/frontdoorpro/hiw-page'),
        fee: getZestyUrl('/proconnect/fee'),
        terms: getZestyUrl('/frontdoorpro/terms-agreement'),
        mappings: getZestyUrl('/proconnect/mappings'),
        serviceType: getZestyUrl('/proconnect/service-type'),
        legal: (slug: string) => getZestyUrl(`/proconnect/${slug}`),
        market: getZestyUrl('/proconnect/market'),
        defaultZipCodeRegion: getZestyUrl('/proconnect/default-zipcode-region'),
        servicePricing: getZestyUrl('/proconnect/service-pricing'),
        reviews: getZestyUrl('/proconnect/reviews'),
        reviewsHeadline: getZestyUrl('/frontdoorpro/reviews-headline'),
        reviewsAndHeadline: (content: string) =>
            getZestyUrl(`/proconnect/${content}`),
        proApply: getZestyUrl('/frontdoorpro/pro-apply'),
        faqContent: getZestyUrl('/frontdoorpor/faq-content'),
        marketsServices: getZestyUrl('/frontdoorpro/markets-services'),
        benefits: getZestyUrl('/proconnect/benefits'),
        inclusionsexclusions: getZestyUrl('/proconnect/inclusionsexclusions'),
        homepagePrices: getZestyUrl('/frontdoorpro/homepage-price'),
        services: getZestyUrl('/proconnect/services'),
        pricesTexts: getZestyUrl('/frontdoorpro/prices_texts'),
        footerLinks: getZestyUrl('/frontdoorpro/footer_links'),
        dynamicPages: getZestyUrl('/proconnect/dynamic_pages'),
        legalHeaderLinks: getZestyUrl('/frontdoorpro/legal-page-header-links'),
        specialOfferDetails: getZestyUrl('/frontdoorpro/special_offer_details'),
        workingHours: getZestyUrl('/frontdoorpro/working-hours'),
    },
    skus: getAPIUrlIntraMesh(
        'od-market-services',
        `/${MARKET_SERVICES_URL}/available-skus`,
    ),
    skusClient: getAPIUrl(`/od-market-services/v1/available-skus`),
    address: getAPIUrl('/address/address'),
    addressVendorPro: getAPIUrl(`/${MARKET_SERVICES_URL}/markets/zipcode`),
    availability: {
        timeslots: getAPIUrlIntraMesh(
            'availability',
            '/availability/v1/timeslots',
        ),
        status: getAPIUrlIntraMesh(
            'availability',
            '/availability/v1/timeslot-availability',
        ),
    },
    availabilityClient: {
        timeslots: getAPIUrl('/availability/v1/timeslots'),
        status: getAPIUrl('/availability/v1/timeslot-availability'),
    },
    marketByZip: getAPIUrlIntraMesh(
        'od-market-services',
        `/${MARKET_SERVICES_URL}/markets/zipcode`,
    ),
    marketByZipClient: getAPIUrl(`/${MARKET_SERVICES_URL}/markets/zipcode`),
    communication: getAPIUrl(`/${VENDOR_PRO_MIDDLEWARE}/communication`),
    geoLocation: getAPIUrl(`/${VENDOR_PRO_MIDDLEWARE}/geo`),
    terms: {
        search: getAPIUrl(
            '/terms-acceptance-service/v1/termsandconditions/search',
        ),
    },
    auth: {
        authenticateHash: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V2}/auth/authenticateHashLink`,
        ),
    },
    appointment: {
        getAppointment: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V2}/appointment/getAppointment`,
        ),
        getAppointments: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V2}/appointment/getAppointments`,
        ),
        reschedule: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/appointment/rescheduleAppointment`,
        ),
        cancel: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/appointment/cancelAppointment`,
        ),
    },
    job: {
        getJob: getServizAPIUrl(`${SERVIZ_API_PREFIX_V2}/shared/getJob`),
        completeBooking: getAPIUrl('/od-job/v1/complete-booking'),
        createAppointment: getAPIUrl('/od-job/v1/appointment'),
    },
    promo: {
        getPromo: getServizAPIUrl(`${SERVIZ_API_PREFIX_V3}/promo/getPromo`),
        applyPromo: getAPIUrl(`/${VENDOR_PRO_MIDDLEWARE}/promo/apply/job`),
    },
    rating: {
        get: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/consumer/getConsumerRatings`,
        ),
        save: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/consumer/saveConsumerRating`,
        ),
    },
    abandonCart: getAPIUrl(`/${VENDOR_PRO_MIDDLEWARE}/abandon`),
    zipcode: getAPIUrl(`/${VENDOR_PRO_MIDDLEWARE}/zipcode`),
    invoice: {
        invoicesByJob: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/processing/getInvoicesByJob`,
        ),
    },
    estimate: {
        estimatesByJob: getServizAPIUrl(
            `${SERVIZ_API_PREFIX_V3}/processing/getEstimatesByJob`,
        ),
    },
    generateHashlink: getServizAPIUrl(
        `${SERVIZ_API_PREFIX_V3}/consumer/generateHashlink`,
    ),
};

function getAPIUrlIntraMesh(service: string, path: string) {
    const base = API_BASE_URL_INTRA_MESH.replace(/service/g, service);

    return `${base}${path}`;
}

function getAPIUrl(path: string) {
    return `${API_BASE_URL}${path}`;
}

function getZestyUrl(path: string) {
    return `${ZESTY_BASE_URL}${path}.json`;
}

function getServizAPIUrl(path: string) {
    return `${SERVIZ_API_BASE_URL}${path}`;
}
