import { createContext, useState } from 'react';
import { NotificationOptions } from '@ftdr/blueprint-components-react';

export interface INotificationOptions
    extends Omit<NotificationOptions, 'icon' | 'children'> {
    boldPrefix?: string;
    text?: string;
}
interface INotificationContext {
    visibleNotifications: Array<INotificationOptions>;
    showNotification: (options: INotificationOptions) => void;
    dismissNotification: (id: string) => void;
    availabilityErrNotification: string;
    setAvailabilityErrNotification: (val: string) => void;
}

export const NotificationContext = createContext<INotificationContext>({
    visibleNotifications: [],
    showNotification: () => null,
    dismissNotification: () => null,
    availabilityErrNotification: '',
    setAvailabilityErrNotification: () => null,
});

function NotificationProvider({ children }: { children: JSX.Element }) {
    const [visibleNotifications, setVisibleNotifications] = useState<
        Array<INotificationOptions>
    >([]);
    const [availabilityErrNotification, setAvailabilityErrNotification] =
        useState('');

    const showNotification = (options: INotificationOptions) => {
        setVisibleNotifications((prev) => [...prev, ...[options]]);
    };

    const dismissNotification = (id: string) => {
        setVisibleNotifications((prev) => {
            return prev.filter((x) => x.id !== id);
        });
    };

    return (
        <NotificationContext.Provider
            value={{
                visibleNotifications,
                showNotification,
                dismissNotification,
                availabilityErrNotification,
                setAvailabilityErrNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export { NotificationProvider };
