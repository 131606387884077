import {
    Notification as BDSNotification,
    NotificationOptions,
    IconDiscountTag,
} from '@ftdr/blueprint-components-react';
import clsx from 'clsx';
import { useContext } from 'react';
import { NotificationContext } from '../../state/notification.context';
import styles from './notification.module.css';

interface INotificationProp extends Omit<NotificationOptions, 'children'> {
    children?: string | JSX.Element;
}

function Notification({
    id,
    status = 'success',
    variant = 'floating',
    showStatusLabel = false,
    autoCloseDelay = 5000,
    icon = <IconDiscountTag />,
    children,
    className,
    ...props
}: INotificationProp) {
    const { visibleNotifications, dismissNotification } =
        useContext(NotificationContext);

    const notification = visibleNotifications.find((x) => x.id === id);
    if (!notification) {
        return null;
    }

    const isFloatingVariant = notification.variant
        ? notification.variant === 'floating'
        : variant === 'floating';

    const notificationContent = isFloatingVariant ? (
        <div className="w-11/12 md:w-full truncate">
            <strong className="text-black">{notification.boldPrefix}</strong>{' '}
            {notification.text}
        </div>
    ) : (
        <>
            <strong>{notification.boldPrefix}</strong> {notification.text}
        </>
    );

    return (
        <BDSNotification
            className={clsx(
                'text-center md:text-left',
                isFloatingVariant && styles.toaster,
                className,
            )}
            status={notification.status || status}
            variant={notification.variant || variant}
            showStatusLabel={notification.showStatusLabel || showStatusLabel}
            autoCloseDelay={notification.autoCloseDelay || autoCloseDelay}
            icon={icon}
            onClose={() => {
                dismissNotification(id);
            }}
            {...props}
        >
            {notification.text ? notificationContent : children || ''}
        </BDSNotification>
    );
}

export { Notification };
