import { createContext, useState } from 'react';
import { IAvailabilitiesData } from '../interfaces/availabilities-data';

interface BookingStateInterface {
    isWide: boolean;
    availabilitiesData: IAvailabilitiesData;
    showRatingsBubble: boolean;
    availabileDays: Date[];
}

interface SemContextInterface {
    booking: BookingStateInterface;
    setIsWideBooking: (isWide: boolean) => void;
    setAvailabilitiesData: (availabilitiesData: IAvailabilitiesData) => void;
    setShowRatingsBubble: (isVisible: boolean) => void;
    setAvailabileDays: (availabileDays: Date[]) => void;
}

const initialBookingState: BookingStateInterface = {
    isWide: false,
    availabilitiesData: {},
    showRatingsBubble: true,
    availabileDays: [],
};

export const SemContext = createContext<SemContextInterface>({
    booking: { ...initialBookingState },
    setIsWideBooking: () => undefined,
    setAvailabilitiesData: () => undefined,
    setShowRatingsBubble: () => undefined,
    setAvailabileDays: () => undefined,
});

function SemProvider({ children }: { children: JSX.Element }) {
    const [booking, setbooking] = useState(initialBookingState);

    const setIsWideBooking = (isWide: boolean) => {
        setbooking((prevBooking) => ({
            ...prevBooking,
            isWide,
        }));
    };

    const setShowRatingsBubble = (showRatingsBubble: boolean) => {
        setbooking((prevBooking) => ({
            ...prevBooking,
            showRatingsBubble,
        }));
    };

    const setAvailabilitiesData = (availabilitiesData: IAvailabilitiesData) => {
        setbooking((prevBooking) => ({
            ...prevBooking,
            availabilitiesData,
        }));
    };

    const setAvailabileDays = (availabileDays: Date[]) => {
        setbooking((prevBooking) => ({
            ...prevBooking,
            availabileDays,
        }));
    };

    const value: SemContextInterface = {
        booking,
        setIsWideBooking,
        setShowRatingsBubble,
        setAvailabilitiesData,
        setAvailabileDays,
    };

    return <SemContext.Provider value={value}>{children}</SemContext.Provider>;
}

export { SemProvider };
